import { useState } from "react";
import { Icon } from "~/components/vendorUI";
import { css, cva } from "ui/css";

const frameCSS = cva({
  base: {
    "--BASE64_ZOOMIN":
      "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSJub25lIj48Y2lyY2xlIGZpbGw9IiNGRkYiIGN4PSI5LjUiIGN5PSI5LjUiIHI9IjguNSIvPjxwYXRoIGQ9Im0yNCAyMi42MjYtNi43NjYtNi43NjZBOS43MTQgOS43MTQgMCAwIDAgOS43MTQgMCA5LjcxNCA5LjcxNCAwIDAgMCAwIDkuNzE0YTkuNzE0IDkuNzE0IDAgMCAwIDE1Ljg2IDcuNTJMMjIuNjI2IDI0IDI0IDIyLjYyNlpNMS45NDMgOS43MTRjMC00LjI4NSAzLjQ4Ni03Ljc3MSA3Ljc3LTcuNzcxIDQuMjg2IDAgNy43NzIgMy40ODYgNy43NzIgNy43NyAwIDQuMjg2LTMuNDg2IDcuNzcyLTcuNzcxIDcuNzcycy03Ljc3MS0zLjQ4Ni03Ljc3MS03Ljc3MVptOC43NDItLjk3Mmg0LjM3MXYxLjk0M2gtNC4zN3Y0LjM3MUg4Ljc0MXYtNC4zN2gtNC4zN1Y4Ljc0MWg0LjM3di00LjM3aDEuOTQzdjQuMzdaIiBmaWxsPSIjMDA5QUQ3Ii8+PC9nPjwvc3ZnPgo=)",
    "--BASE64_ZOOMOUT":
      "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSJub25lIj48Y2lyY2xlIGZpbGw9IiNGRkYiIGN4PSI5LjUiIGN5PSI5LjUiIHI9IjguNSIvPjxwYXRoIGQ9Im0yNCAyMi42MjYtNi43NjYtNi43NjZBOS43MTQgOS43MTQgMCAwIDAgOS43MTQgMCA5LjcxNCA5LjcxNCAwIDAgMCAwIDkuNzE0YTkuNzE0IDkuNzE0IDAgMCAwIDE1Ljg2IDcuNTJMMjIuNjI2IDI0IDI0IDIyLjYyNlpNMS45NDMgOS43MTRjMC00LjI4NSAzLjQ4Ni03Ljc3MSA3Ljc3LTcuNzcxIDQuMjg2IDAgNy43NzIgMy40ODYgNy43NzIgNy43NyAwIDQuMjg2LTMuNDg2IDcuNzcyLTcuNzcxIDcuNzcycy03Ljc3MS0zLjQ4Ni03Ljc3MS03Ljc3MVptMi45MTQtLjk3Mmg5LjcxNHYxLjk0M0g0Ljg1N1Y4Ljc0MloiIGZpbGw9IiMwMDlBRDciLz48L2c+PC9zdmc+Cg==)",
  },
  variants: {
    zoomed: {
      true: {
        zIndex: "overlay",
        position: "fixed",
        inset: 0,
        background: "{colors.black/50}",
        display: "grid",
        placeItems: "center",
        cursor: "var(--BASE64_ZOOMOUT), zoom-out",

        "& img": {
          maxWidth: "90%",
          maxHeight: "100vh",
          cursor: "var(--BASE64_ZOOMOUT), zoom-out",
        },
      },
      false: {
        "& img": {
          maxWidth: "100%",
          cursor: "var(--BASE64_ZOOMIN), zoom-in",
        },
      },
    },
  },
});

const closeIconCSS = css({
  position: "absolute",
  top: "5px",
  right: "5px",
  cursor: "pointer",
});

export const ImageZoom = ({ children }) => {
  const [zoomed, setZoomed] = useState(false);

  const handleZoomToggle = () => setZoomed((s) => !s);

  return (
    <div
      className={frameCSS({ zoomed })}
      onClick={handleZoomToggle}
      data-testid={`zoomed-${zoomed ? "yes" : "no"}`}
    >
      {zoomed && (
        <Icon className={closeIconCSS} name="Cross" color="white" size="24px" />
      )}
      {children}
    </div>
  );
};
